import React from 'react'
import Logo from "../Assests/Logo.png"
import GooglePlay from '../Assests/googlePlay.png'
import { useNavigate } from 'react-router-dom'

export default function Navbar() {

let navigate=useNavigate()

  return (
    <div className='navbarBg'>
        <div className='logo'>
            <img src={Logo} alt="Logo" className='logoNav' onClick={()=>navigate('/')}/>
        </div>
        <div className='menu'>
            <div onClick={()=>navigate('/')}>Home</div>
            <div onClick={()=>navigate('/about')}>About Us</div>
            <div onClick={()=>navigate('/helpCenter')}>Help Center</div>
            <div onClick={()=>navigate('/pricing')}>Pricing</div>
        </div>
        <div className='navbtn'>
            <img src={GooglePlay} alt="GooglePlay" className='GooglePlay navBarGoogleBtn'/>
        </div>
    </div>
  )
}
