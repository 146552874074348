import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function PrivacyPolicy() {
  return (
    <div>
        <Navbar/>
        <div className=' container footerPageContent'>
        <h2 className='PlansHead'>Privacy Policy</h2>
        <p>Tracker Family (“Company” or “we”) provides a service that allows users to share their location with other users in their family and friends available through our mobile apps. (the “Service”)</p>
        <div className='footpagesHead'>PURPOSE OF OUR PRIVACY POLICY</div>
        <p>Under the Data Protection Act and General Data Protection Regulation (GDPR) and the Federal Law “On Personal No. 152 dated July 27, 2006 we are required to explain why we are asking information about you, how we intend to use the information that you provide, and whether we will share this information with anyone else. We take you privacy very seriously and we ask you to carefully read this privacy statement to understand how we collect your information,  what we do with your information, legal basis of processing your personal information, who your information may be shared.</p>
        
        <div className='footpagesHead'>DATA PROTECTION OFFICER FOR US</div>
        <p>Monitoring how we use your information and ensuring compliance with data protection rules are the responsibilities of our data protection officer. Use the email address trackerfamily21@gmail.com to get in touch with our data protection officer if you have any queries or issues about how we utilise your personal information.</p>
        
        <div className='footpagesHead'>WHAT INFORMATION DO WE COLLECT?</div>
        <div className='footpagesHead'>1.Parent App</div>
        <div className='footpagesHead'>Registration and profile information</div>
        <div className='footpagesHead'>When you create an account in our Parent App, we may collect the following information:</div>
        <ul>
            <li>Login with Number</li>
            <li>Get OTP</li>
        </ul>
        
        <div className='footpagesHead'>Registration Details</div>
        <ul>
            <li>Parent Name</li>
            <li>Date of Birth </li>
            <li>Email Id</li>
            <li>Mobile Number</li>
        </ul>
        <div className='footpagesHead'>Child App</div>
        <div className='footpagesHead'>When you create an account in our Child App, we may collect the following information:</div>
        
        <ul>
            <li>Login with Number</li>
            <li>Get OTP </li>
        </ul>
        <p>Registration details-</p>
        <ul>
            <li>Child Name</li>
            <li>Date of Birth</li>
            <li>Email Id</li>
            <li>Parent Registered Mobile Number- Get OTP to verify</li>
            <li>Emergency Contact.</li>
        </ul>

        <div className='footpagesHead'>Permissions : </div>
        <ul>
            <li> Location</li>
            <li>Camera </li>
            <li>Contact</li>
            <li>File & media</li>
            <li>Call Logs</li>
        </ul>
        <div className='footpagesHead'>How we use it :</div>
        <p>To verify the account.</p>
        <p>The features and functionality of the Service are provided, maintained, and operated for you. Send service-related communications to users for communication  as per your selections, to send unsolicited marketing communications</p>

        <div className='footpagesHead'>Basis for the processing is legal:</div>
        <p>The performance of the contract relating to these related activities and services is the basis for this processing.</p>
        <p>For the sake of protecting our legitimate interests, the processing is required for the fraud detection and prevention.</p>

        <div>Cookies are used for the following purposes:</div>
        <p>A “cookie” is a small piece of information stored by a Tracker Family server on a Tracker Family browser. Cookies are useful for enabling the browser to remember information specific to a given User. These cookies do not contain any of User’s personally identifiable information. However, user may choose to disable the Cookies using the settings in his computer system, but in that case the user may face certain difficulties to use the full functionality of the App/web.</p>
        <p>(a) To customize user experience on Tracker Family app/web.</p>
        <p>(b) To obtain information about how users use the App/web, and which pages they visit most often, in order to provide them with services that may be of interest.</p>
        <p>(c) To obtain information about user IP addresses, locations and other demographic information such as age ranges and genders, in order to better understand users’ internet behavior.</p>
        <p>(d) To assist in complying with regulatory obligations such as anti-fraud and anti-money laundering requirements.</p>

        <div className='footpagesHead'>When you utilise a feature of our service:</div>
        <p>Location data & activity from your mobile device, including your IP address, WiFi, Bluetooth, and GPS coordinates (such as latitude and longitude), is available to the account owner and any other family members in order to provide our Service.</p>
        <p>Alerts on all your child’s activities are provided via the geolocation of user-designated “Places.” Although you can disable location sharing to make your location inaccessible to other account holders, the company nonetheless collects your location & all your child activities in order to deliver the Service (for example, display location to family members you allow to see your location).</p>
        
        <div className='footpagesHead'>PRIVACY FOR CHILDREN</div>
        <p>Only with the permission of the parent or another adult who has parental responsibility for the kid may a youngster use the service. Only accounts created in our Service by parents (or other adults with parental responsibility) are compatible with connecting a child’s device.</p>
        <p>You must fill out Permission Form.pdf and email : trackerfamily21@gmail.com  it to us if you wish to add your children under the age of 13 to your account in accordance with the Children’s Online Privacy Protection Act (COPPA) of 1998.</p>
        <p>Only with the parent’s consent and active involvement are services offered.</p>
        <p>The child can use the Service by signing up for the parent’s account.</p>
        <p>Please get in touch with us using the details below if you learn that your child has given us personal information without your permission so that we may take the necessary actions to erase it and close any accounts your child may have registered with us.</p>
        <p>The parent account will be the only place where the child’s personal information is accessible. We will gather and use the child’s personal data in accordance with this privacy statement. We will not utilise child information for advertising purposes and will not forbid our third party partners from doing so. Children under the age of 13 who access and utilise our Service are not contacted by us, and we do not allow third parties to do so either.</p>
        <p>Please get in touch with us at trackerfamily21@gmail.com if you’d like to examine or remove any personal data we’ve acquired about the child or revoke your consent for us to do so.</p>
        
        <div className='footpagesHead'>ANY MODIFICATIONS TO OUR PRIVACY STATEMENT</div>
        <p>Periodically, the company may update or alter the privacy policy. You should periodically examine our privacy statement. The “last changed date” at the bottom of the page is updated whenever we make changes to the Privacy Policy. The company will either immediately send a notification to the user or post a notice on the website informing them of any significant changes to our Privacy Policy or how we will use their personal information.</p>


        <div className='footpagesHead'>GET IN TOUCH</div>
        <p>The user can get in touch with us at trackerfamily21@gmail.com with questions about our privacy policies or anything else.</p>

        <div className='footpagesHead'>Date: 01-03-2023</div>


        </div>
        <Footer/>
    </div>
  )
}
