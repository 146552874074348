import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Assests/Logo.png";

export default function Footer() {
  let navigate = useNavigate();
  return (
    <div className="FooterBg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 footerCol">
            <div>
              <img src={Logo} alt="footerLogo" />
              <div className="footerIcons">
                <i className="fa-brands fa-square-facebook"></i>
                <i className="fa-brands fa-linkedin"></i>
                <i className="fa-brands fa-square-twitter"></i>
              </div>
            </div>

            <div>
              <h5>Contact Us</h5>
              <p>National Self Employment Council</p>
              <p>achildtracker@gmail.com</p>
            </div>
          </div>
          <div className="col-md-4 footerColTwo">
            <div>
              <p onClick={() => navigate("/disclaimer")}>Disclaimer</p>
              <p onClick={() => navigate("/termsCondition")}>
                Terms and Conditions
              </p>
              <p onClick={() => navigate("/termsService")}>Terms of Services</p>
            </div>
          </div>
          <div className="col-md-4 footerColTwo">
            <div>
              <div className="FooterIconsLarge">
                <i className="fa-brands fa-square-facebook"></i>
                <i className="fa-brands fa-linkedin"></i>
                <i className="fa-brands fa-square-twitter"></i>
              </div>
              <p onClick={() => navigate("/refundCancellation")}>
                Cancellation and Refund
              </p>
              <p onClick={() => navigate("/subscriptionPolicy")}>
                Subscription Policy
              </p>
              <p onClick={() => navigate("/privacyPolicy")}>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footertxt">@AllRightsReservedbyTrackerfamily.com</div>
    </div>
  );
}
