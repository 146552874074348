import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function RefundCancellation() {
  return (
    <div>
        <Navbar/>
        <div className=' container footerPageContent'>
        <h2 className='PlansHead'>Refund / Cancellation Policy</h2>
        <p>To reduce last-minute cancellations and the risk of “chargebacks” from customers, it is always a good idea to have your customers agree to your cancellation and refund policy. This should be attached to the customers’ order for future reference. Occasion makes this easy for you and your customers.</p>
        <p>In this article, we will help you define your cancellation and refund policy. Let’s start by answering the following questions:</p>
       
       <ol>
        <li>Do you want to give customers a refund?</li>
        <li>When do they have to inform you by before the actual event date starts to cancel?</li>
        <li>Do you want to keep their payment and give them store credit instead?</li>
       </ol>

       <p>By answering the questions above, you can come up with some very simple and basic policies, like this one: To receive a refund, customers must notify at least 4 days before the start of the event. In all other instances, only a store credit is issued.</p>
        <p>Below are six great examples of cancellation and refund policies:</p>


        <ol>
            <li>Due to limited seating, we request that you cancel at least 48 hours before a scheduled class. This gives us the opportunity to fill the class. You may cancel by phone or online here. If you have to cancel your class, we offer you a credit to your account if you cancel before the 48 hours, but do not offer refunds. You may use these credits towards any future class. However, if you do not cancel prior to the 48 hours, you will lose the payment for the class. The owner has the only right to be flexible here.</li>
            <li>Cancellations made 7 days or more in advance of the event date, will receive a 100% refund. Cancellations made within 3 – 6 days will incur a 20% fee. Cancellations made within 48 hours to the event will incur a 30% fee.</li>
            <li>I understand that I am holding a spot so reservations for this event are nonrefundable. If I am unable to attend I understand that I can transfer to a friend.</li>
            <li>If your cancellation is at least 24 hours in advance of the class, you will receive a full refund. If your cancellation is less than 24 hours in advance, you will receive a gift certificate to attend a future class. We will do our best to accommodate your needs.</li>
            <li>You may cancel your class up to 24 hours before the class begins and request to receive a full refund. If cancellation is made day of you will receive a credit to reschedule at a later date. Credit must be used within 90 days.</li>
            <li>You may request to cancel your ticket for a full refund, up to 72 hours before the date and time of the event. Cancellations between 25-72 hours before the event may transferred to a different date/time of the same class. Cancellation requests made within 24 hours of the class date/time may not receive a refund nor a transfer. When you register for a class, you agree to these terms.</li>
        </ol>
        </div>
        <Footer/>
    </div>
  )
}
