import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function TermsService() {
  return (
    <div>
        <Navbar></Navbar>

        <div className=' container footerPageContent'>
        <h2 className='PlansHead'>Terms of Services</h2>

        <p style={{marginTop:"20px"}}><b>
        The following Terms of Use outline your obligations when using our mobile application (“App”) or Service (as defined Tracker Family ), or any of the information, text, graphics, videos, or other files, materials, data or content of any kind whatsoever created or provided by or through the App or the Service or through your ability to sell products on the App and generate User Contributed Content (as defined herein). Please also review our Privacy Policy, which is a part of these Terms of Use and which outlines our practices towards handling any personal information that you may provide to us.    
        </b></p>

        <div className='footpagesHead'>Acceptance of these Terms of Use</div>
        <p>The App and the Service are owned and operated by Tracker Family. Tracker Family are accessed by you under the Terms of Use described tracker family (“Terms of Use”, “Terms of Service” or “Agreement”). Please read these Terms of Use carefully before using the App or the Service. By accessing the App or using any part of the Service, you agree to become bound by these terms and conditions. If you do not agree to all these terms and conditions, then you may not access the App or use the Service. Nothing in this Agreement shall be deemed to confer any third party rights or benefits.</p>

        <div className='footpagesHead'>Modifications of these Terms of Use</div>
        <p>
        We may revise these Terms of Use from time to time, and the most current version will be available on the App. If the revision(s), at our sole discretion, is considered a material change we may attempt to notify you by posting an announcement on the App. You are responsible for reviewing and becoming familiar with any modification, and you agree to be bound by such modifications or revisions. Use of the App or the Service after any such revision(s) constitutes your acceptance of the terms and conditions of these Terms of Use as modified.
        </p>
        </div>
        <Footer></Footer>
    </div>
  )
}
