import React from 'react'
import ParentFile from "../Assests/parent.apk"
import ChildFile from "../Assests/child.apk"
import { Link } from 'react-router-dom'



export default function Banner(props) {
  return (
    <div className='bannerBg'>
        <div className='container'>
            <div className='row BannerRow' >
                <div className='col-md-6 bannerColFlex'>
                    <h2 data-aos="fade-right"  data-aos-duration="1000">{props.text}</h2>
                    <Link to={ParentFile} target="_blank" download>
                      <button className='downLoadBtn'>Download Parent App</button>
                    </Link> 
                    <Link to={ChildFile} target="_blank" download>
                      <button className='downLoadBtn'>Download Child App</button>
                    </Link>  
                </div>
                <div className='col-md-6 bannerColFlex'>
                  <img src={props.image} alt="GooglePlay" className='BannerImg' data-aos="fade-left"  data-aos-duration="1000"/>
                </div>
            </div>
        </div>
    </div>
  )
}
