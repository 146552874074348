import React from 'react'
import mobileOne from '../Assests/mobileOne.png'
import mobileTwo from '../Assests/mobileTwo.png'
import mobileThree from '../Assests/mobileThree.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Section() {
  AOS.init();


  return (
    <div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 sectionColFlex order-md-1 order-2' >
                <img src={mobileOne} alt="mobileOne" className='Mobile' data-aos="fade-right"  data-aos-duration="1000"/>
                </div>
                <div className='col-md-6 sectionColTxt order-md-2 order-1'>
                  <div data-aos="fade-left"  data-aos-duration="1000">
                    <h1>Keep track of locations</h1>
                    <p>Want to see the location of your kids on the map? Enable location tracking to make it easier to keep track of their whereabouts. Location tracking makes it easier to arrange pick-ups and provides peace of mind to parents everywhere.</p>
                  </div>
                </div>

                </div>
                <div className='row'>
                
                <div className='col-md-6 sectionColTxt order-1'>
                  <div data-aos="fade-right"  data-aos-duration="1000">
                <h1>You Can Check the Call History</h1>
                <p>Parents can check the call history of kids. To whom they called to someone what was the calling time of kids. </p>
                </div>
                </div>

                <div className='col-md-6 sectionColFlex order-2'>
                <img src={mobileTwo} alt="mobileOne" className='Mobile' data-aos="fade-left"  data-aos-duration="1000"/>
                </div>

                </div>
                <div className='row'>

                <div className='col-md-6 sectionColFlex order-md-1 order-2'>
                <img src={mobileThree} alt="mobileOne" className='Mobile' data-aos="fade-right"  data-aos-duration="1000"/>
                </div>
                <div className='col-md-6 sectionColTxt order-md-2 order-1'>
                  <div data-aos="fade-left"  data-aos-duration="1000">
                  <h1>You can check which app kids are using in their phone.</h1>
                <p>If you install child Tracking app from playstore, you can check that which apps are installed in your kids phone. </p>
                
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}
