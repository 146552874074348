import React from 'react'
import Banner from './Banner'
import Footer from './Footer'
import Navbar from './Navbar'
import bannerImage from '../Assests/Family.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function About() {
  AOS.init();
  return (
    <div>
        <Navbar/>
        <Banner image={bannerImage} text="Tracker Family is a free professional Tracker apps platform"/>

        <div>
            <h2 className='PlansHead'>About Us</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 AboutCol'>
                    <div className='aboutWidth'>
                    <p>Hello Friends, welcome to our website <b>Tracker Family</b>, founded on 20 January 2023 by <b>Tracker Family</b>.</p>

                    <p><b>Tracker Family</b> is a free professional Tracker apps platform where we provide child Tracker Apps, Tracker Apps, Mobile Tracker in child etc. with a focus on dependability and daily. It is one of the basic needs of our everyday life. However, there are thousands of websites for child Tracker Apps, Tracker Apps, Mobile Tracker in child etc. but here you will find a useful and comprehensive content. We hope you enjoy our child Tracker Apps, Tracker Apps, Mobile Tracker in child as much as we enjoy offering them to you.</p>

                    <p>If you have any question or query regarding our website, please contact us by visiting our Contact Us page or mailling us at achildtracker@gmail.com .</p>
                    </div>
                    </div>
                </div>
            </div> 
        </div>
        <Footer/>
    </div>
  )
}
