import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import BannerImg from "../Assests/priceBanner.png"
import Navbar from './Navbar'
import Footer from "./Footer"
import axios from 'axios'
import Modal from 'react-modal';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth:"40%",
    maxWidth:"90%"
  },
};

Modal.setAppElement('#root');

export default function Pricing() {
    AOS.init();

const [data,setData]=useState([])
const [modalValue,setModalValue]=useState(false);
const [parentNum,setParentNum]=useState()
const [userId,setUserId]=useState()
const [userName,setUserName]=useState()
const [userEmail,setUserEmail]=useState()
const [userPhone,setUserPhone]=useState()
const [amount,setAmount]=useState(0)
const [plan,setPlan]=useState("")
const [gst,setGst]=useState(0)

    const handleChildrens = () => {
        axios
          .get("https://sea-lion-app-9q8xh.ondigitalocean.app/api/getAllSubscriptionWithoutToken")
          .then((item) => {
            setData(item.data.getAll.reverse());
          })
          .catch((e) => {
            console.log("error", e);
          });
      };
      useEffect(() => {
        handleChildrens();
      }, []);


    const handleLogin=(e)=>{
        setModalValue(true)
        setAmount(e.subscriptionPrice);
        setPlan(e.subscriptionTenure)
        localStorage.setItem("amount",e.subscriptionPrice);
        localStorage.setItem("plan",e.subscriptionName);
    }



  const handleParentNumber=(e)=>{
    setParentNum(e.target.value)
  }


    const handleVerify=()=>{
      axios
      .post("https://sea-lion-app-9q8xh.ondigitalocean.app/api/parentLoginForInstaMojo",{
        
          phone:parentNum
        
      })
      .then((res) => {
        setUserId(res.data.parent._id)
        setUserName(res.data.parent.fullName)
        setUserEmail(res.data.parent.email)
        setUserPhone(res.data.parent.phone)
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("You Are Not Parent")
      });
    }

    const paymentData={
      userId:userId,
      purpose:"buying Plan",
      amount:amount,
      plan:plan,
      redirectUrl:`https://sea-lion-app-9q8xh.ondigitalocean.app/api/instamojoSuccess?userId=${userId}&amount=${amount}&plan=${plan}`,
      buyer_name:userName,
      email:userEmail,
      phone:userPhone
    }


    const [redirectUrl,setRedirectUrl]=useState("")

     const handlePayment=()=>{
      axios
      .post("https://sea-lion-app-9q8xh.ondigitalocean.app/api/instamojoPayment",paymentData)
      .then((res) => {
       setRedirectUrl(res.data.redirecturl)
      })
      .catch((e) => {
        console.log("handlePaymentError", e);
      });
    }
    


    useEffect(()=>{
      if(userId){
        handlePayment();
      }
    },[userId])


    useEffect(()=>{
      if(redirectUrl){
        window.location.href=redirectUrl
      }
    },[redirectUrl])


    useEffect(()=>{
      setGst((amount*18)/100)

    },[amount])

    console.log("gst",gst)

    const finalAmount=(gst)+(amount);

    console.log("totalAmount",finalAmount)

  return (
    <div>
        <Navbar/>
        <Banner image={BannerImg} text="A Child Tracker Parental App Pricing & Registration"/>

        <div>
            <h2 className='PlansHead'>Subscription Plans</h2>
            <div className='container-fluid mt-4'>
                <div className='row'>
                    {data.map((item)=>{
                        return(
                        <div className='col-xl-3 col-md-4 col-sm-6 cards' key={item._id}>
                            <div className='cardBg' onClick={()=>handleLogin(item)} data-aos="zoom-in" data-aos-duration="1000">
                                <div className='CardUpper'>
                                    <div className='cardFont'>{item.subscriptionName}</div>
                                </div>
                                <div className='CardLower'>
                                    <div>{item.subscriptionTenure} Months</div>
                                    <div>Rs {item.subscriptionPrice}</div>
                                    <p>Including GST</p>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                    
                </div>
            </div> 
        </div>


        <Footer/>



        <Modal isOpen={modalValue} style={customStyles}>
            <div style={{backgroundColor:"#DF34E8",width:"30px",textAlign:"center",color:"white",
            position:"absolute",top:"0px",right:"0px",cursor:"pointer"}} onClick={()=>setModalValue(false)}>
                X
            </div>
            <div style={{textAlign:"center",fontSize:"20px",fontWeight:"500",paddingBottom:"30px"}}>Verify to Get Subscription</div>

            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <span style={{marginRight:"20px"}}>Parent Mobile Number:</span>
            <input type="text" onChange={handleParentNumber}/>
            </div>

            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <button style={{backgroundColor:"#DF34E8",color:"#fff",border:"none",fontSize:"18px",padding:"5px 20px",marginTop:"20px",marginLeft:"20px"}}
                    onClick={()=>handleVerify()}>
              Verify
            </button>
            </div>
        </Modal>
        <ToastContainer/>
    </div>
  )
}
