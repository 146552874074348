import React from 'react'
import Banner from './Banner'
import Footer from './Footer'
import Navbar from './Navbar'
import Section from './Section'
import BannerImg from '../Assests/banner.png'


export default function Home() {
  return (
    <>
    <Navbar/>
    <Banner image={BannerImg} text="Best Parental Control Monitoring App"/>
    <Section/>
    <Footer/>
    </>  
  )
}
