import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Subscription() {
  return (
    <div>
        <Navbar/>
        <div className=' container footerPageContent'>
        <h2 className='PlansHead'>Subscription Policy</h2>
        </div>
        <Footer/>
    </div>
  )
}
