import './App.css';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Home from './Component/Home';
import HelpCenter from './Component/HelpCenter';
import Pricing from './Component/Pricing';
import About from './Component/About';
import Disclaimer from './Component/Disclaimer';
import TermsCondition from './Component/TermsCondition';
import TermsService from './Component/TermsService';
import RefundCancellation from './Component/RefundCancellation';
import Subscription from './Component/Subscription';
import PrivacyPolicy from './Component/PrivacyPolicy';
import Success from './Component/Success';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/helpCenter" element={<HelpCenter/>}></Route>
      <Route path="/pricing" element={<Pricing/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/disclaimer" element={<Disclaimer/>}></Route>
      <Route path="/termsCondition" element={<TermsCondition/>}></Route>
      <Route path="/termsService" element={<TermsService/>}></Route>
      <Route path="/refundCancellation" element={<RefundCancellation/>}></Route>
      <Route path="/subscriptionPolicy" element={<Subscription/>}></Route>
      <Route path="/privacyPolicy" element={<PrivacyPolicy/>}></Route>
      <Route path="/success" element={<Success/>}></Route>

    </Routes>
  </BrowserRouter>
  );
}

export default App;
