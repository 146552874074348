import React from 'react'
import Banner from './Banner'
import BannerImg from "../Assests/helpImage.png"
import Navbar from './Navbar'
import mailIcon from "../Assests/mailIcon.png";
import supportImage from "../Assests/banner.png"
import Footer from "./Footer"
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function HelpCenter() {
    AOS.init();
  return (
    <div>
    <Navbar/>
    <Banner image={BannerImg} text="Download the App Now"/>
    <div className='supportBg'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 mailBox'>
                    <div className='mailIconTxt'><img src={mailIcon} alt="mailIcon" className='mailIcon'></img>Support Email</div>
                    <p  data-aos="fade-right" data-aos-duration="1000">achildtracker@gmail.com</p>
                    <p  data-aos="fade-right" data-aos-duration="1000" data-aos-delay="1000">+91 6289106924</p>
                </div>
                <div className='col-md-6 mailBox'>
                    <img src={supportImage} alt="supportImage" className='supportImage'/>
                </div>
            </div>
        </div>
    </div>

    <div>
        <h3 className='Frequently'>Frequently Asked Questions</h3>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='quesAns'>
                        <p className='ques'>My child device doesn't send its location?</p>
                        <p className='ans'>To see your child on the map again, you need to have their phone next to you.</p>
                    </div>

                    <div className='quesAns'>
                        <p className='ques'>The most common reason is network problems on the child's phone?</p>
                        <p className='ans'>The accuracy of the coordinates depends on a number of factors. We try to use all the available sources of coordinates on the device. The location is formed on GPS, Wi-Fi (some models of watches, iOS and Android devices) and mobile networks.</p>
                    </div>

                    <div className='quesAns'>
                        <p className='ques'>How do I cancel my subscription on Play Store ?</p>
                        <p className='ans'>You can cancel the subscription on your phone, just follow our tips below: 1. Open Play Store or Google Play on your phone 2. Tap your profile icon in the upper right corner 3. Choose Payments & subscriptions 4. Tap “Subscriptions” 5. Go to the Find My Kids subscription 6. Tap "Cancel subscription" Follow the tips from Google and the subscription will be cancelled</p>
                    </div>
                </div>


                <div className='col-md-6'>
                    <div className='quesAns'>
                        <p className='ques'>Why the sound around is not working?</p>
                        <p className='ans'>The most common reason is network problems on the child's phone</p>
                    </div>

                    <div className='quesAns'>
                        <p className='ques'>How to connect child’s and parent’s apps?</p>
                        <p className='ans'>1. Install on a child’s phone or a tablet the app Tracker Family 2. Install on your phone the app Find My Kids 3. Choose Connect a child’s phone in the app Tracker Family.. You will get a code that has 6 Numbers. 4. Enter the code in the child’s app (you can also send this code as a sms or in any messenger you prefer) 5. Accept Terms of Use in our app, set the child’s age, gender, name and give all the necessary permissions for the app’s correct work The code is valid for30 sec</p>
                    </div>

                    <div className='quesAns'>
                        <p className='ques'>What are the benefits of the Tracker Family subscription</p>
                        <p className='ans'>With a subscription you’ll get the many app features if your child has an Android phone.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </div>
  )
}
