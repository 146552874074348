import React from 'react'
import { useNavigate } from 'react-router-dom'
import SuccessImg from "../Assests/success.png"

export default function Success() {

    let navigate=useNavigate()

  return (
    <div style={{height:"100vh",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <img src={SuccessImg} alt="successImage"/>
        <p style={{padding:"20px",cursor:"pointer"}} onClick={()=>navigate("/")}><i className="fa-solid fa-arrow-left-long"  style={{paddingRight:"10px"}}></i>Go To Website</p>
    </div>
  )
}
